@import "themes";

div.mat-menu-panel {
    box-shadow: nb-theme(context-menu-shadow);
    border-color: nb-theme(context-menu-border-color);
    border-style: nb-theme(context-menu-border-style);
    border-width: nb-theme(context-menu-border-width);
    border-radius: nb-theme(context-menu-border-radius);
    min-width: nb-theme(context-menu-min-width);
    background-color: nb-theme(context-menu-background-color);
    max-width: none !important;
    min-height: auto !important;
}

div.mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
}

button.mat-menu-item {
    color: nb-theme(option-text-color);
    font-family: nb-theme(option-text-font-family);
    font-size: nb-theme(option-medium-text-font-size);
    padding: nb-theme(option-medium-padding);
    background-color: nb-theme(context-menu-background-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mat-menu-item-highlighted:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item:hover:not([disabled]) {
    background-color: nb-theme(option-hover-background-color);
    color: nb-theme(option-hover-text-color);
}

mat-divider {
    display: block;
    margin: 0;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgba(0, 0, 0, .12);
}
