@import "themes";

$enabled-themes: nb-get-enabled-themes();

@each $theme-name in $enabled-themes {
    @include nb-pre-process-context($theme-name);
    .nb-theme-#{$theme-name} {
        ng-select {
            width: 100%;
            @each $size in nb-get-sizes() {
                &.size-#{$size} {
                    .ng-select-container {
                        min-height: 40px;
                        border-radius: nb-theme(border-radius);
                        border-style: nb-theme(input-border-style);
                        border-width: nb-theme(input-border-width);
                        font-family: nb-theme(input-text-font-family), sans-serif;

                        appearance: none; // removes inner shadow on iOS
                        font-size: nb-theme(input-#{$size}-text-font-size);
                        font-weight: nb-theme(input-#{$size}-text-font-weight);
                        line-height: nb-theme(input-#{$size}-text-line-height);
                        padding: nb-theme(input-#{$size}-padding);

                        &::placeholder {
                            font-size: nb-theme(input-#{$size}-placeholder-text-font-size);
                            font-weight: nb-theme(input-#{$size}-placeholder-text-font-weight);
                            // TODO: this seems to be unused
                            line-height: nb-theme(input-#{$size}-placeholder-text-line-height);
                        }

                        &.nb-transition {
                            @include nb-component-animation(border, background-color, color, box-shadow);
                        }

                        &::placeholder {
                            font-family: nb-theme(input-placeholder-text-font-family), sans-serif;
                            text-overflow: ellipsis;
                        }

                        &:focus {
                            outline: none;
                        }

                        &.input-full-width {
                            width: 100%;
                        }
                    }
                }
            }

            @each $status in nb-get-statuses() {
                &.status-#{$status} {
                    .ng-select-container {
                        background-color: nb-theme(input-#{$status}-background-color);
                        border-color: nb-theme(input-#{$status}-border-color);
                        color: nb-theme(input-#{$status}-text-color);

                        &::placeholder {
                            color: nb-theme(input-#{$status}-placeholder-text-color);
                        }

                        &:focus {
                            background-color: nb-theme(input-#{$status}-focus-background-color);
                            border-color: nb-theme(input-#{$status}-focus-border-color);
                        }

                        &:hover {
                            background-color: nb-theme(input-#{$status}-hover-background-color);
                            border-color: nb-theme(input-#{$status}-hover-border-color);
                        }

                        &:disabled {
                            background-color: nb-theme(input-#{$status}-disabled-background-color);
                            border-color: nb-theme(input-#{$status}-disabled-border-color);
                            color: nb-theme(input-#{$status}-disabled-text-color);

                            &::placeholder {
                                color: nb-theme(input-#{$status}-disabled-placeholder-text-color);
                            }
                        }
                    }
                }
            }
        }
    }
}

.ng-select.ng-select-opened > .ng-select-container {
    background: #fff;
    border-color: #b3b3b3 #ccc #d9d9d9
}

.ng-select.ng-select-opened > .ng-select-container:hover {
    box-shadow: none
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    top: -2px;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
    border-color: transparent transparent #333
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)
}

.ng-select.ng-select-disabled > .ng-select-container {
    background-color: #f9f9f9
}

.ng-select .ng-has-value .ng-placeholder {
    display: none
}


.ng-select .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px
}

[dir="rtl"] .ng-select .ng-arrow-wrapper {
    padding-left: 5px;
    padding-right: 0
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
    border-top-color: #666
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px
}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0;
    font-family: nb-theme(input-text-font-family), sans-serif;
}

.ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: #e6e6e6;
    margin-top: -1px
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.ng-dropdown-panel.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

.ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid #ccc;
    padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    padding: 8px 10px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background-color: #f5faff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
    background-color: #ebf5ff;
    font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 8px 10px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #333;
    background-color: #ebf5ff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #f5faff;
    color: #333
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: #ccc
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 22px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-right: 22px;
    padding-left: 0
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    font-size: 80%;
    font-weight: 400;
    padding-right: 5px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-left: 5px;
    padding-right: 0
}

[dir="rtl"] .ng-dropdown-panel {
    direction: rtl;
    text-align: right
}
