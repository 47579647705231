@import "themes";

.tag {
    display: inline-block;
    padding: .35em .65em;
    font-size: 1em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}

.tag-bg-primary {
    background-color: nb-theme(color-primary-600);
}

.tag-bg-success {
    background-color: nb-theme(color-success-600);
}

.tag-bg-danger {
    background-color: nb-theme(color-danger-600);
}

.tag-bg-info {
    background-color: nb-theme(color-info-600);
}

.tag-bg-warning {
    background-color: nb-theme(color-warning-600);
}

.tag-bg-black {
    background-color: black;
}
