@import './themes';
@import '~@angular/material/theming';

$enabled-themes: nb-get-enabled-themes();

@each $theme-name in $enabled-themes {
    @include nb-pre-process-context($theme-name);

    .nb-theme-#{$theme-name} {
        .mcv-window-button {
            padding: nb-theme(button-filled-tiny-padding);
            background-color: nb-theme(button-filled-basic-background-color);
            border-radius: nb-theme(border-radius);
            border-color: transparent;
            color: nb-theme(color-basic-800);
            cursor: pointer;

            &:hover {
                background-color: nb-theme(color-basic-hover);
            }
        }

        .mcv-card {
            width: 75vw;
            background-color: nb-theme(card-background-color);
            border: nb-theme(card-border-width) nb-theme(card-border-style) nb-theme(card-border-color);
            border-radius: nb-theme(card-border-radius);

            &.active {
                &.regular {
                    // box-shadow: nb-theme(context-menu-shadow);
                }
            }

            color: nb-theme(card-text-color);
            font-family: nb-theme(card-text-font-family);
            font-size: nb-theme(card-text-font-size);
            font-weight: nb-theme(card-text-font-weight);
            line-height: nb-theme(card-text-line-height);

            @include nb-scrollbars(
                    nb-theme(card-scrollbar-color),
                    nb-theme(card-scrollbar-background-color),
                    nb-theme(card-scrollbar-width));

            @each $size in nb-get-sizes() {
                &.size-#{$size} {
                    height: nb-theme(card-height-#{$size});
                }
            }

            @each $status in nb-get-statuses() {
                &.status-#{$status} {
                    .mcv-card-header {
                        background-color: nb-theme(card-header-#{$status}-background-color);
                        border-bottom-width: 0;
                        border-bottom-color: nb-theme(card-header-#{$status}-background-color);
                        color: nb-theme(card-header-#{$status}-text-color);

                        a,
                        a:hover {
                            color: nb-theme(card-header-#{$status}-text-color);
                        }
                    }
                }
            }

            @each $status in nb-get-core-statuses() {
                &.accent-#{$status} {
                    border-top-color: nb-theme(card-header-#{$status}-background-color);
                }
            }

            &.accent {
                border-top-style: nb-theme(card-border-style);
                border-top-width: nb-theme(card-border-radius);

                & .mcv-card-header {
                    border-radius: 0;
                }
            }
        }

        .mcv-card-body {
            flex: 1;
            -ms-flex: 1 1 auto;
            overflow: auto;
            min-height: 33vh;
            padding: nb-theme(card-padding);
            position: relative;
            @include nb-scrollbars(
                    nb-theme(card-scrollbar-color),
                    nb-theme(card-scrollbar-background-color),
                    nb-theme(card-scrollbar-width));
        }

        .mcv-card-footer {
            padding: nb-theme(card-padding);
            border-top: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
            border-bottom-left-radius: nb-theme(card-border-radius);
            border-bottom-right-radius: nb-theme(card-border-radius);
        }

        .mcv-card-header {
            @include nb-card-header();
        }

        .windowmanager-wrapper {
            @for $zValue from 0 through 24 {
                &.#{$mat-elevation-prefix}#{$zValue} {
                    &.active:not(.minimize) {
                        @include mat-elevation($zValue);
                    }

                    &:not(.active):not(.minimize) {
                        @include mat-elevation($zValue, black, 0.5);
                    }
                }
            }
        }
    }
}

.ngx-json-viewer {
    //overflow: auto !important;
}
