@import "themes";

.w-100 {
    width: 100%;
}

.text-center {
    text-align: center;
    justify-content: center;
}

.text-left {
    text-align: left;
    justify-content: center;
}

li.tab {
    &:focus {
        outline: none;
    }

    & a {
        outline: none;
    }
}

div.title {
    &:focus {
        outline: none;
    }
}


.blink-success {
    animation: blinker-success 1s linear;
    animation-iteration-count: 3;
}

@keyframes blinker-success {
    50% {
        opacity: 0;
        background-color: nb-theme(color-success-400);
    }
}

.blink-danger {
    animation: blinker-danger 1s linear;
    animation-iteration-count: 3;
}

@keyframes blinker-danger {
    50% {
        opacity: 0;
        background-color: nb-theme(color-danger-400);
    }
}

.blink-primary {
    animation: blinker-primary 1s linear;
    animation-iteration-count: 3;
}

@keyframes blinker-primary {
    50% {
        opacity: 0;
        background-color: nb-theme(color-primary-400);
    }
}

.notifier__notification {
    &--success > p {
        color: white !important;
    }

    &--error > p {
        color: white !important;
    }
}

.ngx-json-viewer {
    font-size: 1.5rem !important;
}

nb-window {
    &.large:not(.minimized) {
        width: 75vw;
        height: 75vh;

        & > nb-card {
            max-height: 75vh;

            & > nb-card-body {
                overflow: auto;
            }
        }
    }

    &.medium:not(.minimized) {
        width: 50vw;
    }

    &.small:not(.minimized) {
        width: 33vw;
    }
}

nb-dialog-container {
    padding: nb-theme(default-padding);
    //background-color: nb-theme(background-basic-color-1);
}

.badge {
    &-danger {
        background-color: nb-theme(color-danger-500);
    }

    &-info {
        background-color: nb-theme(color-info-500);
    }

    &-primary {
        background-color: nb-theme(color-primary-500);
    }

    &-success {
        background-color: nb-theme(color-success-500);
    }

    &-warning {
        background-color: nb-theme(color-warning-500);
    }
}

fieldset {
    border-radius: nb-theme(border-radius);
    border-color: nb-theme(color-basic-300);

    & > legend {
        padding: nb-theme(default-padding);
    }
}

label:not(.toggle-label) {
    font-weight: 600;
    display: inline-block;
    margin-bottom: .5rem;
}

val-errors {
    color: nb-theme(color-danger-500);
}

label.required:after {
    content: " *";
    color: red;
}


nb-tabset ul {
    overflow: scroll hidden !important;
}

@media (max-width: 1365px) {
    nb-tabset ul {
        justify-content: flex-start !important;
    }
}

nb-tabset .tab-link::before {
    margin-bottom: 0.25rem !important;
}

.menu-sidebar.left .menu-item .menu-title {
    width: 7rem;
}
