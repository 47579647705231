@mixin ngx-pace-theme() {

    .pace .pace-progress {
        background: nb-theme(color-primary-default);
    }

    .pace .pace-progress-inner {
        box-shadow: 0 0 10px nb-theme(color-primary-default), 0 0 5px nb-theme(color-primary-default);
    }

    .pace .pace-activity {
        display: none;
    }
}
