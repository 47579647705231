@import "themes";

$enabled-themes: nb-get-enabled-themes();

@each $theme-name in $enabled-themes {
    @include nb-pre-process-context($theme-name);

    .nb-theme-#{$theme-name} {
        .mat-calendar-body-cell-content {
            top: 5%;
            left: 5%;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            width: 90%;
            height: 90%;
            line-height: 1;
            border-width: 1px;
            border-style: solid;
            border-radius: 999px;
        }

        .mat-calendar-body-cell-content, .mat-date-range-input-separator {
            color: nb-theme(color-basic-800);
            border-color: transparent;
        }

        .mat-datepicker-popup {
            font-family: nb-theme(card-text-font-family), sans-serif;
            background-color: nb-theme(card-background-color);
        }

        .mat-calendar-body-cell {
            position: relative;
            height: 0;
            line-height: 0;
            text-align: center;
            outline: none;
            cursor: pointer;
        }

        .mat-calendar-body-cell-content:hover {
            background-color: nb-theme(color-basic-hover);
        }

        .mat-calendar-body-selected {
            background-color: nb-theme(button-filled-basic-background-color);
        }
    }
}
