@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/default';
//@import '~@nebular/theme/styles/themes/dark';

$nb-enable-css-custom-properties: false;

$nb-themes: nb-register-theme((
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: #f7f9fc,
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    default-padding : 1rem,
    context-menu-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 25%),
    button-filled-tiny-padding: 0.3125rem 0.3125rem,
    button-filled-small-padding: 0.4375rem 0.4375rem,
    button-filled-medium-padding: 0.6875rem 0.6875rem,
    button-filled-large-padding: 0.8125rem 0.8125rem,
    button-filled-giant-padding: 0.9375rem 0.9375rem,
    tabset-tab-padding: 1rem,
    sidebar-width: 12rem
),
    default, default
);
/*
$nb-themes: nb-register-theme((
        layout-padding-top: 2.25rem,
        menu-item-icon-margin: 0 0.5rem 0 0,
        card-height-tiny: 13.5rem,
        card-height-small: 21.1875rem,
        card-height-medium: 28.875rem,
        card-height-large: 36.5625rem,
        card-height-giant: 44.25rem,
        card-margin-bottom: 1.875rem,
        card-header-with-select-padding-top: 0.5rem,
        card-header-with-select-padding-bottom: 0.5rem,
        select-min-width: 6rem,
        slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
        slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
        slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
        default-padding : 1rem,
        color-basic-hover: color-basic-900,
        context-menu-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75),
        tabset-tab-padding: 1rem 2rem,
), dark, dark);
 */
