@import "themes";

table.cdk-table {
    width: 100%;
    border-collapse: collapse;

    & thead {
        & tr {
        }

        & th {
            padding: nb-theme(default-padding);
            border: 1px solid nb-theme(smart-table-separator);
            background-color: nb-theme(color-basic-transparent-600);

            &.text-center > div.mat-sort-header-container {
                justify-content: center;
            }
        }
    }

    & tbody {
        /* & tr:hover {
             background-color: nb-theme(color-basic-hover);
         }*/

        & td {
            padding: nb-theme(default-padding);
        }

        & tr {
            &.selected, &:hover {
                background: nb-theme(color-basic-transparent-300) !important;
            }

            &.striped3:nth-child(3n) {
                background-color: nb-theme(color-info-100);
            }

            &:not(.no-background):nth-child(2n) {
                background-color: nb-theme(color-info-100);
            }

            &.no-padding {
                & > td {
                    padding: 0;
                }
            }

            & {
                & td {
                    position: relative;
                    padding: nb-theme(smart-table-padding);
                    border: 1px solid nb-theme(smart-table-separator);
                    vertical-align: middle;
                }
            }
        }
    }
}
